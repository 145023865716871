<template>
  <div
    class="vLightPreloader"
    :class="[{ notFixed: !fixed }, { transparent: transparent }]"
    :style="{ background: background }"
  >
    <img
      class="vLightPreloader__ico"
      src="~@/assets/images/light-preloader.gif"
      alt="loading..."
    >
    <div
      v-if="text"
      class="vLightPreloader__text"
    >
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'VLightPreloader',
  props: {
    fixed: {
      type: Boolean,
      required: false,
      default: true,
    },
    transparent: {
      type: Boolean,
      required: false,
      default: false,
    },
    background: {
      type: String,
      required: false,
      default: 'transparent',
    },
    text: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss">
.vLightPreloader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(220, 222, 227, 0.5);
  backdrop-filter: blur(7px);
  &__ico {
    width: 110px;
    height: 110px;
  }
  &__text {
    max-width: 280px;
    text-align: center;
    font-weight: 600;
    color: #010d35;
    font-family: 'Commissioner';
    font-size: 24px;
    margin-bottom: 63px;
  }
  &.notFixed {
    position: absolute;
  }
  &.transparent {
    background: #fff !important;
  }
}
</style>
